import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { routes } from '../app.routes';

import { FeathersService } from '../services/feathers.service';

@Injectable({
  providedIn: 'root'
})
export class AppPagesService {
  private appPagesSubject = new BehaviorSubject<any[]>([]);
  public appPages$ = this.appPagesSubject.asObservable();

  constructor(
    private feathersService: FeathersService,
    private router: Router
  ) {
    this.generateAppPages();
  }

  private generateAppPages() {

    console.log('[generateAppPages]', this.feathersService);

    const appPages = routes
      .filter((route: any) => route.data && route.data.showInMenu)
      .map((route: any) => ({
        title: route.data.title,
        url: `/${route.path}`,
        icon: route.data.icon
      }));
    this.appPagesSubject.next(appPages);
  }
}
