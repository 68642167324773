import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    IonApp,IonRouterOutlet,IonContent,IonMenu,IonMenuToggle,IonList,IonListHeader,IonNote,IonItem,IonIcon,IonLabel
} from '@ionic/angular/standalone';

import { Observable } from 'rxjs';
import { AppPagesService } from './services/app-pages.service';
import { RouterLink } from '@angular/router';

import { addIcons } from 'ionicons';
import { homeOutline, bookOutline, diceOutline, callOutline, fastFoodOutline, imagesOutline, calendarOutline, megaphoneOutline, mailOpenOutline } from 'ionicons/icons';



@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true,
    imports: [
        CommonModule, RouterLink, IonApp, IonRouterOutlet, IonContent, IonMenu, IonMenuToggle, IonList, IonListHeader, IonNote, IonItem, IonIcon, IonLabel
    ],
})
export class AppComponent  {
    appPages$: Observable<any[]>;

    constructor(private appPagesService: AppPagesService) {
        this.appPages$ = this.appPagesService.appPages$;
        addIcons({
            homeOutline, bookOutline, diceOutline, callOutline, fastFoodOutline, imagesOutline, calendarOutline, megaphoneOutline, mailOpenOutline
        });


    }


}