// import { isDevMode } from '@angular/core';

import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, from, interval } from 'rxjs';

import { createClient } from 'odin-back'
import io from 'socket.io-client';
import socketio from '@feathersjs/socketio-client';

@Injectable({
  providedIn: 'root'
})
export class FeathersService {
  private backend_server = environment.backendUrl;
  private _socket: any;
  private _feathers: any;
  private _user!: any;

  // Store Socket Status
  private jwtExpiration: number = -1;
  private jwtExpirationSubj: Subject<number> = new ReplaySubject<number>(-1);
  private socketStatusSubj: ReplaySubject<any> = new ReplaySubject<any>();

  constructor() {
    // Socket Connection
    this._socket = socketio(io(this.backend_server));

    // Initialize the Feathers client with the connection
    this._feathers = createClient(this._socket);

    // JWT Expiration
    interval(1 * 1000).subscribe(val => this.updateJwtExpiration())
  }

  updateSocketStatus(status: string, reason: string = '', err?: any) {
    console.log('[updateSocketStatus]', { status }, { reason }, { err });

    let message;
    switch (reason) {
      case 'io server disconnect':
        message = 'Sei stato disconnesso dal Server';
        break;
      case 'io client disconnect':
        message = 'Sei stato disconnesso dal Client';
        break;
      case 'ping timeout':
        message = 'Il server non risponde';
        break;
      case 'transport close':
        message = 'Internet Assente?';
        break;
      case 'transport error':
        message = 'Errore Connessione';
        break;
      default:
        message = status === 'Connected' ? 'Connesso' : 'Disconnesso';
    }

    let severity;
    let toastSeverity;
    switch (status) {
      case 'Connected':
        severity = 'success';
        toastSeverity = 'success';
        break;
      case 'Disconnected':
        severity = 'danger';
        toastSeverity = 'error';
        break;
      case 'Connection Error':
        severity = 'warning';
        toastSeverity = 'warn';
        break;
    }

    this.socketStatusSubj.next({
      status: status,
      reason: reason,
      message: message,
      severity: severity,
      toastSeverity: toastSeverity
    });
  }

  updateJwtExpiration() {
    if (this.jwtExpiration === -1) return;

    let exp = new Date(this.jwtExpiration * 1000);
    let now = new Date();
    let diff = exp.getTime() - now.getTime();
    let seconds = Math.floor(diff / 1000) - 30;

    if (seconds > 0) {
      this.jwtExpirationSubj.next(seconds);

    } else {
      this.jwtExpirationSubj.next(0);
      this.jwtExpirationSubj.next(-1);
      this.jwtExpiration = -1;
    }
  }

  // Socket connection status
  getSocketStatus(): Observable<any> {
    // console.log('[getSocketStatus]')
    return this.socketStatusSubj.asObservable();
  }

  // JWT Expiration
  getJwtExpiration(): Observable<number> {
    return this.jwtExpirationSubj.asObservable();
  }

  async getUser() {
    if (!this._user) {
      const auth = await this._feathers.authenticate();
      this._user = auth.user;
    }
    return this._user;
  }

  getServer() {
    return this.backend_server;
  }

  async getJwt() {
    const auth = this._feathers.authentication;
    const jwt = (auth.options.storage.storage as any)['feathers-jwt'];

    return jwt;
  }

  // expose Auth
  authenticate(email: string, password: string): Promise<any> {
    return this._feathers.authenticate({ strategy: 'local', email, password })
      .then((result: any) => {
        console.log('[authenticate]', result);

        if (result.user) {
          this._user = result.user;
        }

        if (result['authentication']?.payload.exp) {
          this.jwtExpiration = result['authentication']?.payload.exp;
        }

        return result;
      });
  }

  reAuthenticate(): Promise<any> {
    return this._feathers.reAuthenticate()
      .then((result: any) => {
        if (result && result['authentication']?.payload.exp) {
          this.jwtExpiration = result['authentication']?.payload.exp;
        }

        return result;
      });
  }

  public logout() {
    return this._feathers.logout();
  }

  // expose socket
  public socket() {
    return this._socket;
  }
  // expose services
  public service(name: string) {
    return this._feathers.service(name);
  }

  public findObservable(name: string, query: any): Observable<any> {
    console.log('findObservable', name, query);
    return from(this._feathers.service(name).find({ query }));
  }

  // expose services find observable
  public find$(name: string, query?: any): Observable<any> {
    return from(this._feathers.service(name).find(query));
  }

  // expose authentication
  public authentication() {
    return this._feathers.authentication;
  }
}
